import { getDefaultOptions } from 'API/auth'
import { getUrl } from 'API/getUrl'
import { handleApi } from 'API/handleApiCall'
import axios from 'axios'
import { AssetType } from '../pages/course/[id]/activities/assignment/[assignment_id]'

export async function getAssignmentData(assignment_id: string, viewAs: string | undefined) {
	return await handleApi<AssetType>(
		axios.get(getUrl(`asset/assignment?assignment_id=${assignment_id}&asTeacher=${viewAs === 'Teacher'}`), getDefaultOptions())
	)
}
