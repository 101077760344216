import axios from 'axios'
import { getDefaultOptions } from './auth'
import { getUrl } from './getUrl'
import { handleApi } from './handleApiCall'

export const updateAssignmentMarks = async (data: { assignment_id: string; user_id: string; marks: number; grades?: string }) => {
	return handleApi(
		axios.post(
			getUrl('asset/assignment_marks'),
			{
				...data,
				marks: data?.grades !== undefined ? null : data.marks,
			},
			getDefaultOptions()
		)
	)
}
