import { Icon } from '@iconify/react'
import { updateAssignmentMarks } from 'API/assignment'
import { getDefaultOptions } from 'API/auth'
import { getUrl } from 'API/getUrl'
import { handleApi } from 'API/handleApiCall'
import axios from 'axios'
import { Form } from 'components/Form/Form'
import { Link, PageProps } from 'gatsby'
import { useGetCourseDetails } from 'hooks/useGetCourseDetails'
import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { UserType } from 'types/UserAdditionMetaData'
import Loader from 'react-js-loader'
import { addFileToCDN } from '../../classwork/add/addFileToCDN'
import 'styles/index.scss'
import { ListSubmissions } from './ListSubmissions'
import { AssignmentStateActions } from 'redux/slices/AssignmentState'
import { setUpdate } from 'redux/slices/ApplicationState'
import { NonUndefined, PromiseType } from 'utility-types'
const ReactQuill = typeof window !== 'undefined' ? require('react-quill') : () => <></>

import { getAssignmentData } from '../../../../../API/getAssignmentData'
import 'quill/dist/quill.snow.css'
import 'styles/index.scss'
export type AssetType = {
	token?: string
	allowMultiple?: boolean
	name: string
	description: string
	doc: boolean
	available_from: string
	available_till: string
	visible_from: string
	marks_type?: 'marks' | 'grades' | null
	marks: number
	__t?: string
	cdn?: {
		original_name?: string
		name?: string
		file_extension?: string
	}
}

const AssignmentView: React.FC<PageProps> = ({ params: { id, assignment_id } }) => {
	const { ShowDetails, viewAs } = useGetCourseDetails(id)
	const [loading, setLoading] = useState(false)
	const { asset } = useAssignmentData(assignment_id, viewAs)
	return (
		<>
			<div className='mt-4 mb-4 px-0 classworkDetailsContainer container base'>
				{ShowDetails}
				{viewAs === 'Student' && asset && (
					<>
						<div className='row modalclass'>
							<div className='col-lg-8 col my-3 '>
								<Row className='mt-4'>
									{/* <Col lg={1}></Col> */}
									<Col lg={10} xs={12} className='ml-lg-4'>
										<Row>
											<Col lg={11} xs={10}>
												<h2 className='mediaTitle text-primary'>{asset.name}</h2>
											</Col>
											<Col lg={1} xs={2}>
												<i className='bx bx-dots-vertical-rounded mt-3 mt-lg-2 bx-sm float-right'></i>
											</Col>
										</Row>
										<Row className='mb-3'>
											<Col lg={6}>
												<span className='float-lg-left font-weight-bold'>Points: {asset.marks}</span>
											</Col>

											<Col lg={6}>
												<span className='float-lg-right font-weight-bold'>
													Due by:{' '}
													{new Date(asset.available_till).toLocaleString(undefined, {
														day: 'numeric',
														month: 'short',
														hour12: false,
														minute: '2-digit',
														hour: '2-digit',
														second: '2-digit',
													})}
												</span>
											</Col>
										</Row>
										<Row>
											<Col lg={12} className='card'>
												<ReactQuill
													theme='snow'
													value={asset.description}
													readOnly={true}
													modules={{
														toolbar: false,
													}}
												/>
											</Col>
										</Row>
										<>
											<Link
												to={`${getUrl(
													`asset/assignment_attachment_download/${asset?.cdn?.original_name}?assignment_id=${assignment_id}&token=${asset.token}`
												)}`}
												target='_blank'
												rel='noopener noreferrer'
												className='btn btn-primary'
											>
												<span>
													<Icon icon='bx:bxs-download' className='mx-1 mr-3' />
													Download
												</span>
											</Link>
											{/* </div> */}
											<Row className='mt-3 mb-5 mx-1'>
												{asset.token && (
													<iframe
														style={{ width: '100%', height: '25rem' }}
														src={
															asset.doc
																? `https://view.officeapps.live.com/op/embed.aspx?src=${getUrl(
																		`asset/assignment_attachment?assignment_id=${assignment_id}&token=${asset.token}`
																  )}`
																: getUrl(
																		`asset/assignment_attachment?assignment_id=${assignment_id}&token=${asset.token}`
																  )
														}
														frameBorder='0'
													></iframe>
												)}
											</Row>
										</>
									</Col>
									<Col lg={2}></Col>
								</Row>
							</div>
							<div className='col-lg-4 my-3'>
								{viewAs === 'Student' &&
									new Date(asset.available_from) < new Date() &&
									new Date(asset.available_till) > new Date() && (
										<AddSubmission
											allowMultiple={asset?.allowMultiple}
											isSubmitted={true}
											{...{ course_id: id, assignment_id }}
										/>
									)}
								{viewAs === 'Student' && new Date(asset.available_till) < new Date() && (
									<ShowSubmission allowMultiple={asset?.allowMultiple} {...{ course_id: id, assignment_id }} />
								)}
							</div>
						</div>
					</>
				)}

				{viewAs === 'Teacher' && <ShowSubmissionForStudent marks_type={asset?.marks_type} {...{ id, assignment_id, course_id: id }} />}
			</div>
		</>
	)
}

export default AssignmentView

const useGetUserAssignment = ({ user_id, assignment_id }: { user_id?: string; assignment_id?: string }) => {
	const [state, setState] = useState<PromiseType<ReturnType<typeof getUserAssignmentAPICall>>>()

	const update = useAppSelector((state) => state.ApplicationReducer.update)
	useEffect(() => {
		const run = async () => {
			if (user_id && assignment_id) {
				const data = await getUserAssignmentAPICall(assignment_id, user_id)

				if (data) {
					if (!data.cdn) {
						setState({ ...data, cdn: undefined })
					} else setState(data)
				} else {
					// @todo fixme
					;(setState as any)((state: any) => ({ ...state, cdn: undefined, createdAt: '', grades: undefined }))
				}
			}
		}
		run()
	}, [user_id, assignment_id, update])
	return { state, setState }
}
// @todo
export const ShowSubmissionStatus: React.FC<{ user_id: string; assignment_id: string }> = (data) => {
	const { state } = useGetUserAssignment(data)
	const viewAs = useAppSelector((state) => state.CourseDisplayReducer.viewAs)
	const { asset } = useAssignmentData(data.assignment_id, viewAs)
	const [marks, setMarks] = useState(0)
	const update = useAppSelector((state) => state.ApplicationReducer.update)

	useEffect(() => {
		if (state?.marks) setMarks(state.marks)
	}, [state, update])

	// console.log({ asset })

	return (
		<>
			<td>
				<form
					onSubmit={async (e) => {
						e.preventDefault()
						updateAssignmentMarks({
							...data,
							marks,
						})
						toast('Updated', { type: 'success' })
					}}
				>
					<input
						type='number'
						onChange={(e) => setMarks(parseInt(e.target.value))}
						min={0}
						max={asset?.marks}
						value={marks}
						defaultValue={state?.marks ?? 0}
					/>
					/{asset?.marks}
					<button type='submit' className='btn btn-primary'>
						Update Marks
					</button>
				</form>
			</td>
			<td>{state && <Submitted allowMultiple={asset?.allowMultiple} submission={state} />}</td>
		</>
	)
}

const ShowSubmission: React.FC<{ course_id: string; assignment_id: string; allowMultiple?: boolean }> = ({
	course_id,
	assignment_id,
	allowMultiple,
}) => {
	const [submitted, setSubmitted] = useState(false)
	const [submission, setSubmission] = useState<{ createdAt: string; cdn: { uid: string; name: string; original_name: string } }>()
	useEffect(() => {
		const run = async () => {
			const data = await handleApi<{ createdAt: string; cdn: { name: string; uid: string; original_name: string } } | undefined>(
				axios.get(getUrl(`asset/get_assignment_submission?assignment_id=${assignment_id}`), getDefaultOptions())
			)
			if (data === undefined || !data) {
				setSubmitted(false)
			} else {
				setSubmitted(true)
				setSubmission(data)
			}
		}
		run()
	}, [course_id, assignment_id])

	return (
		<>
			<div className=''>
				{submitted && <Submitted allowMultiple={allowMultiple} submission={submission} />}
				{!submitted && <>You Missed the deadline.</>}
			</div>
		</>
	)
}
const AddSubmission: React.FC<{ course_id: string; assignment_id: string; isSubmitted: boolean; allowMultiple?: boolean }> = ({
	course_id,
	assignment_id,
	isSubmitted,
	allowMultiple,
}) => {
	const [submitted, setSubmitted] = useState(false)
	const [loading, setLoading] = useState(false)
	const [submission, setSubmission] = useState<{ createdAt: string; cdn?: { uid: string; name: string; original_name: string } }>()
	useEffect(() => {
		const run = async () => {
			const data = await handleApi<{ createdAt: string; cdn: { name: string; uid: string; original_name: string } } | undefined>(
				axios.get(getUrl(`asset/get_assignment_submission?assignment_id=${assignment_id}`), getDefaultOptions())
			)
			if (data === undefined || !data) {
				setSubmitted(false)
			} else {
				setSubmitted(true)
				setSubmission(data)
			}
		}
		run()
	}, [course_id, assignment_id])

	useEffect(() => {
		const run = async () => {
			if (submitted) {
				const data = await handleApi<{ createdAt: string; cdn: { name: string; uid: string; original_name: string } } | undefined>(
					axios.get(getUrl(`asset/get_assignment_submission?assignment_id=${assignment_id}`), getDefaultOptions())
				)
				if (data === undefined || !data) {
					throw new Error('Undefined Behavior')
				} else {
					setSubmission(data)
				}
			}
		}
		run()
	}, [submitted])
	// if (submitted === hideOnSubmitted) return <></>
	return (
		<>
			<h1 className='pt-2'>Submission</h1>
			{loading && <Loader type='spinner-circle' bgColor={'#1266cc'} size={100} />}
			{!submitted && (
				<Form
					expanded={true}
					text={'Add Submission'}
					action={async ({ state }) => {
						setLoading(true)
						const resp = await addFileToCDN({ ...state, name: 'submission_' + assignment_id })
						if (resp) {
							const resp_ = await handleApi(
								axios.post(
									getUrl('asset/submit_assignment'),
									{
										assignment_id,
										cdn_id: resp.uid,
									},
									getDefaultOptions()
								)
							)
							setLoading(false)
							if (resp_) setSubmitted(true)
						}
					}}
					fields={[
						{
							label: 'Select File',
							name: 'file',
							type: 'image',
						},
					]}
				></Form>
			)}

			{submitted && <Submitted allowMultiple={allowMultiple} submission={submission} />}
		</>
	)
}

async function getUserAssignmentAPICall(assignment_id: string, user_id: string) {
	return await handleApi<{
		createdAt: string
		cdn?: { name: string; uid: string; original_name: string; cdn_token: string; cdn_file: string }
		marks: number
		grades?: string
	}>(axios.get(getUrl(`asset/get_assignment_submission?assignment_id=${assignment_id}&asTeacher=${true}&user_id=${user_id}`), getDefaultOptions()))
}

export const getAssignmentSubmissionList = async (assignment_id: string) =>
	await handleApi<(UserType & { submitted: boolean; marks: number; givenMarks: number; grades?: string })[]>(
		axios.get(getUrl('asset/getSubmissionList?assignment_id=' + assignment_id), getDefaultOptions())
	)

function ShowSubmissionForStudent({
	assignment_id,
	marks_type,
}: {
	id: string
	assignment_id: string
	course_id: string
	marks_type?: 'marks' | 'grades' | null
}) {
	const [users, setUsers] = React.useState<NonUndefined<PromiseType<NonUndefined<ReturnType<typeof getAssignmentSubmissionList>>>>>([])
	const dispatch = useAppDispatch()
	const update = useAppSelector((state) => state.ApplicationReducer.update)
	React.useEffect(() => {
		const run = async () => {
			const submissionList = await getAssignmentSubmissionList(assignment_id)

			if (submissionList) {
				setUsers(submissionList)
				console.log({ submissionList })
			}
		}
		dispatch(AssignmentStateActions.setAssignmentId(assignment_id))
		run()
	}, [update])
	const user_id_selected = useAppSelector((state) => state.AssignmentReducer.user_id)
	return (
		<>
			<div className='app-content content overflow-hidden'>
				<div className='content-overlay'></div>
				<div className='header-navbar-shadow'></div>
				<div className='content-wrapper animate__animated animate__fadeIn'>
					<div className='app-calendar overflow-hidden border'>
						<div className='no-gutters row'>
							<div
								id='app-calendar-sidebar'
								className={`col app-calendar-sidebar grading ${
									true
										? // isListVisible
										  'show'
										: ''
								}  flex-grow-0 overflow-hidden d-flex flex-column col`}
							>
								<div className='sidebar-wrapper scrollStudentList'>
									<ListSubmissions
										type={'assignment'}
										marks_type={marks_type}
										user_id={user_id_selected}
										users={users}
									></ListSubmissions>
								</div>
							</div>

							<div className='position-relative col'>
								<div className='shadow-none border-0 mb-0 rounded-0 card'>
									<div className='pb-0 card-body  px-0'>{false ? <></> : user_id_selected && <SelectedAssignment />}</div>
								</div>
							</div>
							<div className={`body-content-overlay ${false ? 'show' : ''}`}></div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

const SelectedAssignment: React.FC = () => {
	const assignment_id = useAppSelector((state) => state.AssignmentReducer.assignment_id)
	const user_id = useAppSelector((state) => state.AssignmentReducer.user_id)
	const { first_name, last_name } = useAppSelector((state) => state.AssignmentReducer)
	const { state } = useGetUserAssignment({
		assignment_id,
		user_id,
	})
	const viewAs = useAppSelector((state) => state.CourseDisplayReducer.viewAs)
	const { asset } = useAssignmentData(assignment_id, viewAs)
	const [marks, setMarks] = useState(0)
	const [grades, setGrades] = useState('')
	const update = useAppSelector((state) => state.ApplicationReducer.update)
	const dispatch = useAppDispatch()

	useEffect(() => {
		if (asset) {
			if (asset.marks_type === 'grades') {
				if (state?.grades) setGrades(state.grades)
				else setGrades('')
			} else if (state?.marks) setMarks(state.marks)
		}
		console.log(asset)
		console.log(state)
	}, [state, update])
	return (
		<>
			<>
				<div className='scrollStudentList'>
					<div className='row'>
						<Col md={1} />
						<Col md={11}>
							<h1>
								{first_name} {last_name}
							</h1>
							{asset?.marks_type === 'grades' ? (
								<>
									<h3>
										Grade: <code>{grades ?? 'Not Graded'}</code>
									</h3>
								</>
							) : (
								<>
									<h3>
										Obtained Marks: <code>{state?.marks}</code>
									</h3>
									<h3>
										Total Marks: <code>{asset?.marks}</code>
									</h3>
								</>
							)}
						</Col>
					</div>
					{/* <Row className='mx-2'>
						<button className='d-block mx-3  btn btn-primary d-lg-none fc-sidebarToggle-button fc-button fc-button-primary' type='button'>
							<span className='fa fa-bars'></span>
						</button>
					</Row> */}
					<>
						<Row className='mx-3 my-2'>
							<Col xs={12} md={9} className='my-3'>
								{/* <h5>{props.submissionData.name}</h5> */}
								{/* <h6 className='text-muted'>Marked</h6> */}
							</Col>
							<Col xs={12} md={3} className='my-3'>
								<h4>
									{/* {props.submissionData.status === 'graded' ? ( */}
									<div>
										{/* <span>{10}</span> */}
										{asset?.marks_type === 'grades' ? (
											<>
												<span className='text-muted'>{state?.grades ?? 'Not Graded'}</span>
											</>
										) : (
											<span className='text-muted'>
												{state?.marks}/{asset?.marks}
											</span>
										)}
									</div>
									{/* <span className='text-muted'>Not Graded</span> */}

									<div>
										{asset?.marks_type === 'grades' ? (
											<>
												<form
													method='post'
													onSubmit={async (e) => {
														e.preventDefault()
														if (assignment_id && user_id) {
															await updateAssignmentMarks({
																assignment_id,
																user_id,
																grades,
																marks: 0,
															})
															toast('Updated', { type: 'success' })
															dispatch(setUpdate())
														}
													}}
												>
													<input
														type='text'
														onChange={(e) => setGrades(e.target.value)}
														value={grades}
														defaultValue={grades}
														id='giveGrade'
														className='form-control'
													/>
													<button type='submit' className='btn no-hover no-shadow btn-sm mt-2 btn-primary'>
														{/* {props.submissionData.status === 'graded' ? 'Update Grade' : 'Add Grade'} */}
														Add Grades
													</button>
												</form>
											</>
										) : (
											<>
												<form
													method='post'
													onSubmit={async (e) => {
														e.preventDefault()
														if (assignment_id && user_id) {
															await updateAssignmentMarks({
																assignment_id,
																user_id,
																marks,
															})
															toast('Updated', { type: 'success' })
															dispatch(setUpdate())
														}
													}}
												>
													<input
														type='number'
														onChange={(e) => setMarks(parseInt(e.target.value))}
														min={0}
														max={asset?.marks}
														value={marks ?? 0}
														defaultValue={state?.marks ?? 0}
														id='giveGrade'
														className='form-control'
													/>
													<button type='submit' className='btn no-hover no-shadow btn-sm mt-2 btn-primary'>
														{/* {props.submissionData.status === 'graded' ? 'Update Grade' : 'Add Grade'} */}
														Add Marks
													</button>
												</form>
											</>
										)}
									</div>
								</h4>
							</Col>
						</Row>
						<Row className='mx-4 my-2'>
							<>
								<>
									{' '}
									<Col xs={5}>
										<a
											href={
												state?.cdn?.cdn_token ? getUrl(`static/${state?.cdn?.cdn_file}?token=${state?.cdn.cdn_token}`) : ''
												// resolveUrl(`submission/download_submission/${'props.id'}`)
											}
											target='_blank'
											rel='noopener noreferrer'
										>
											<div className='subThumb my-3 border rounded d-inline-block'>
												<Row>
													<Col xs={3}>
														<img src={''} className='subImg px-2 mb-0 border rounded' alt='' />
													</Col>
													<Col xs={9} className='my-auto'>
														<h6 className='my-0 mx-3 text-reset'>
															{first_name} {last_name}
														</h6>
													</Col>
												</Row>
											</div>
										</a>
										{/* <SubmissionThumbnail key={submission.id} id={submission.id} filename={submission.fileName} /> */}
									</Col>
									{state?.cdn && (
										<p
											className='btn btn-primary my-2'
											onClick={async (e) => {
												e.preventDefault()
												const data = await handleApi(
													axios.get(
														state?.cdn?.cdn_token
															? getUrl(`static/${state?.cdn?.cdn_file}?token=${state?.cdn?.cdn_token}`)
															: '',
														{
															...getDefaultOptions(),
															responseType: 'arraybuffer',
														}
													)
												)
												if (window !== undefined && data) {
													const url = window.URL.createObjectURL(new Blob([data as any]))
													const link = document.createElement('a')
													link.href = url
													link.setAttribute('download', state?.cdn?.original_name ?? '') //or any other extension
													document.body.appendChild(link)
													link.click()
												}
											}}
										>
											<Icon icon='bx:bxs-download' className='mx-1 mr-3' />
											Download Submission
										</p>
									)}
									{state?.cdn?.cdn_token ? (
										<iframe
											title='resource'
											src={`${
												state?.cdn.cdn_file ? getUrl(`static/${state?.cdn.cdn_file}?token=${state.cdn.cdn_token}`) : ''
											}#toolbar=0`}
											style={{ width: '100%', height: '25rem' }}
										></iframe>
									) : (
										<></>
									)}
								</>
							</>
							{/* )})} */}
						</Row>
						<div className='px-4 py-4 border-top' style={{ marginTop: '5rem' }}>
							{/* <ForumComment /> */}
						</div>
					</>
					{/* <StudentWorkDetail setUpdate={setUpdate} submissionData={submissionData}></StudentWorkDetail> */}
				</div>
			</>
		</>
	)
}

export function useAssignmentData(assignment_id?: string, viewAs?: string) {
	const update = useAppSelector((state) => state.ApplicationReducer.update)
	const [asset, setAsset] = useState<AssetType>()
	useEffect(() => {
		const run = async () => {
			if (assignment_id) {
				const asset = await getAssignmentData(assignment_id, viewAs)

				if (asset) {
					setAsset(asset)
				}
			}
		}
		run()
	}, [assignment_id, viewAs, update])

	return { asset, setAsset }
}

function Submitted({
	submission,
	allowMultiple,
}: {
	submission?: {
		createdAt: string
		assignment?: string
		cdn?: {
			uid: string
			name: string
			original_name: string
		}
	}
	allowMultiple?: boolean
}) {
	console.log({ submission })
	return (
		<>
			{submission?.cdn && (
				<p
					className='btn btn-primary my-3'
					onClick={async (e) => {
						e.preventDefault()
						const data = await handleApi(
							axios.get(getUrl(`cdn/get_file?uid=${submission?.cdn?.uid}`), {
								...getDefaultOptions(),
								responseType: 'arraybuffer',
							})
						)
						if (window !== undefined && data) {
							const url = window.URL.createObjectURL(new Blob([data as any]))
							const link = document.createElement('a')
							link.href = url
							link.setAttribute('download', submission?.cdn?.original_name ?? '') //or any other extension
							document.body.appendChild(link)
							link.click()
						}
					}}
				>
					<Icon icon='bx:bxs-download' className='mx-1 mr-3' />
					Download Submission
					{/* {submission?.cdn?.name} {submission?.createdAt && <>at {new Date(submission.createdAt).toString()}</>} */}
				</p>
			)}
			{allowMultiple === true && (
				<>
					<div className=''>
						<p
							onClick={async () => {
								await axios.post(
									getUrl('asset/delete_submitted_assignment'),
									{
										assignment_id: submission?.assignment,
									},
									{
										...getDefaultOptions(),
									}
								)
								window.location.reload()
							}}
							className='btn btn-danger my-3'
						>
							Delete and Re-Submit
						</p>
					</div>
				</>
			)}
		</>
	)
}
